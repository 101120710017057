// import Image from 'next/image';
// import Link from 'next/link';
import { Link } from 'gatsby';
import React from 'react';

const ContactOverviewCard = ({ singleItem }) => {
  const { icon, tilte, desc, link, contact } = singleItem;
  return (
    <div className='contact__overview__single column__space--secondary shadow__effect'>
      <img src={icon} alt='email' />
      <h5>{tilte}</h5>
      <p>{desc}</p>
      <hr />
      <p className='neutral-bottom'>
        <Link to={link}>{contact}</Link>
      </p>
    </div>
  );
};

export default ContactOverviewCard;
