import React from 'react';
import imgback from '../../public/images/banner/banner-two-bg.png';

const Banner = () => {
  return (
    <section
      className='banner__alt bg__img'
      style={{
        backgroundImage: `url(${imgback})`,
      }}
    >
      <div className='container'>
        <div className='banner__alt__area'>
          <h1 className='neutral-top neutral-bottom'>Browse Properties</h1>
        </div>
      </div>
    </section>
  );
};

export default Banner;
