const faqData = [
  {
    id: '3@24e',
    category: 'start',
    title: 'What is Arackal Developers?',
    details:
      "Arackal Developers' online platform enables investing in real estate. Through our portal, investors can invest for good returns in a secure environment without facing many of the traditional challenges of owning and operating an income earning land.Investors can browse their accounts through our login portal, review the due diligence materials of the Real Estates, and if interested in investing in a real estate, clients can invest in that property.Investors can invest in our company on shares for a good returns.Transfer money and sign legal documents securely online.Investors can access information on their investments via our investor dashboard 24/7.",
  },
  {
    id: '3@64e',
    category: 'start',
    title: 'What are the benefits of investing via Arackal Developers?',
    details:
      'Arackal Developers makes it easy for investors to safely invest in land without being required to do all of the work and without real estate operational knowledge. We spend countless hours reviewing potential land listings to find investment-grade opportunities, and our model allows individuals to invest in the asset without having to buy an entire plots of land. We make investing easy by allowing investors to review potential offerings and sign legal documents online, as well as access all documents in one place through the investor dashboard.Our mission is to empower our customers to Buy and Sell Land Smarter with advanced technology, data, and expertise. Land is our passion. Land is essential for the wellbeing of individuals, families, communities, and our world.Buying and selling land becomes common, transparent, and easy.Investment is 100% secure, because it is backed by the security of the Land. Land value will not depreciate.The price of raw land will increase more than 3 times, when it is developed. Developed land produces additional income also. ',
  },
];

export default faqData;
