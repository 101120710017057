import { useFormik } from 'formik';
import React, { useState } from 'react';
import axios from 'axios';
import { Alert, CircularProgress } from '@mui/material';

const Ask = () => {
  const apiBaseURL = process.env.GATSBY_CONTACT_BASE_URL;

  const [alert, setAlert] = useState({
    message: '',
    show: false,
    loading: false,
    status: 'success',
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastname: '',
      message: '',
      subject: '',
    },
    onSubmit: (values) => {
      setAlert({ ...alert, loading: true });
      const { email, firstName, lastname, subject, ...rest } = values;
      axios
        .post(
          `${apiBaseURL}/v1/organizations/arackal-developers/projects/arackal-developers/contact-forms`,
          {
            email: email,
            first_name: firstName,
            last_name: lastname,
            subject: subject,
            metadata: rest,
          }
        )
        .then(() => {
          setAlert({
            message:
              'Thank you for reaching out to us. We have received your message and will respond as soon as possible.',
            show: true,
            status: 'success',
          });
          setTimeout(() => {
            setAlert({ ...alert, show: false, loading: false });
          }, 3000);
        })
        .catch((error) => {
          setAlert({
            message: 'Something went wrong, ' + error,
            show: true,
            status: 'error',
            loading: false,
          });
          setTimeout(() => {
            setAlert({ ...alert, show: false, loading: false });
          }, 3000);
        });
      formik.resetForm();
    },
  });

  return (
    <section className='ask section__space bg__img'>
      <div className='container'>
        <div className='ask__area'>
          <div className='alert__newsletter__area'>
            <div className='section__header'>
              <h2 className='neutral-top'>Ask a Question</h2>
            </div>
            <form>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='input input--secondary'>
                    <label htmlFor='askFirstName'>First Name*</label>
                    <input
                      type='text'
                      name='firstName'
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      id='askFirstName'
                      placeholder='Enter Your First Name'
                      required='required'
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='input input--secondary'>
                    <label htmlFor='askLastName'>Last Name*</label>
                    <input
                      type='text'
                      name='lastname'
                      onChange={formik.handleChange}
                      value={formik.values.lastname}
                      id='askLastName'
                      placeholder='Enter Your Last Name'
                      required='required'
                    />
                  </div>
                </div>
              </div>
              <div className='input input--secondary'>
                <label htmlFor='askRegistrationMail'>Email*</label>
                <input
                  type='email'
                  name='email'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  id='askRegistrationMail'
                  placeholder='Enter your email'
                  required='required'
                />
              </div>

              <div className='input input--secondary'>
                <label htmlFor='askSubject'>Subject*</label>
                <input
                  type='text'
                  name='subject'
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  id='askSubject'
                  placeholder='Write Subject'
                  required='required'
                />
              </div>
              <div className='input input--secondary'>
                <label htmlFor='askMessage'>Message*</label>
                <textarea
                  name='message'
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  id='askMessage'
                  required='required'
                  placeholder='Write Message'
                ></textarea>
              </div>
              {alert.show && (
                <Alert severity={alert.status}>{alert.message}</Alert>
              )}
              <div className='input__button'>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                  className='button button--effect'
                >
                  {alert.loading ? (
                    <CircularProgress sx={{ color: '#ccc' }} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ask;
