import React from 'react';
import Ask from '../../../components/contactUs/Ask';
import ContactOverview from '../../../components/contactUs/ContactOverview';
import Banner from '../../../components/property/Banner';
import ContactUs from '../../../pages/contact-us';
import { Layout } from '../../components/common';

const cont = process.env.GATSBY_BASE_URL;

const Contact = () => {
  return (
    <Layout>
      <ContactUs />
    </Layout>
  );
};

export default Contact;
