import React from 'react';

const Banner = () => {
  return (
    <section className='support__banner contact__banner bg__img clear__top'>
      <div className='container'>
        <div className='support__banner__area'>
          <div className='support__banner__inner'>
            <h1 className='neutral-top'>How can we help?</h1>
            <h5 className='neutral-top'>Got a question?</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
