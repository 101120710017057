import ContactOverviewCard from '../cards/ContactOverviewCard';
import chat from '/public/images/icons/chat.png';
import email from '/public/images/icons/email.png';
import phone from '/public/images/icons/phone.png';
import React from 'react';

const contactOverviewtaData = [
  {
    id: '$kasd$5',
    icon: email,
    tilte: 'Send Us an Email',
    desc: 'Our team is available to answer any questions you may have.',
    link: 'mailto:info@arackalproperties.com',
    contact: 'arackalplantations@gmail.com',
  },
  {
    id: '$k5d$5',
    icon: phone,
    tilte: 'Give Us a Call',
    desc: 'Our team is available to answer any questions you may have.',
    link: 'tel:+919037495671',
    contact: '+919037495671' + ', +919961277763',
  },
];

const ContactOverview = () => {
  return (
    <section className='contact__overview'>
      <div className='container'>
        <div className='contact__overview__area'>
          <div className='row'>
            {contactOverviewtaData.map((singleItem) => (
              <div key={singleItem.id} className='col-md-6 col-xl-6'>
                <ContactOverviewCard singleItem={singleItem} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactOverview;
